dmx.Component('slideshow', {

    initialData: {
        running: false,
        index: 0
    },

    tag: 'div',

    attributes: {
        slides: {
            type: Array,
            default: []
        },

        'slide-url': {
            type: String,
            default: 'url'
        },

        'slide-title': {
            type: String,
            default: 'title'
        },

        'slide-description': {
            type: String,
            default: 'description'
        },

        'slide-link': {
            type: String,
            default: 'link'
        },

        transitions: {
            type: Object,
            default: { default: {} }
        },

        delay: {
            type: Number,
            default: 4000
        },

        'show-nav': {
            type: Boolean,
            default: false
        },

        'show-paging': {
            type: Boolean,
            default: false
        },

        'pause-on-hover': {
            type: Boolean,
            default: false
        },

        'start-random': {
            type: Boolean,
            default: false
        },

        'no-autostart': {
            type: Boolean,
            default: false
        }
    },

    methods: {
        start: function() {
            this.start();
        },

        stop: function() {
            this.stop();
        },

        prev: function(effect, options) {
            this.prev(effect, options);
        },

        next: function(effect, options) {
            this.next(effect, options);
        },

        show: function(index, effect, options) {
            this.show(index, false, effect, options);
        }
    },

    render: function(node) {
        this.moveListener = this.touchMove.bind(this);
        this.endListener = this.touchEnd.bind(this);

        this.$node = document.createElement('div');
        this.$node.className = node.className;
        this.$node.classList.add('dmx-slideshow', 'dmx-slideshow-loading');
        this.$node.id = this.name;
        this.$node.style.width = node.style.width;
        this.$node.style.height = node.style.height;
        node.parentNode.replaceChild(this.$node, node);

        this.slidesContainer = document.createElement('div');
        this.slidesContainer.classList.add('dmx-slideshow-slides-container');

        this.slider = document.createElement('div');
        this.slider.className = 'dmx-slideshow-slider';
        this.slider.style.setProperty('position', 'relative');

        this.effectsContainer = document.createElement('div');
        this.effectsContainer.classList.add('dmx-slideshow-effects-container');

        this.textboxContainer = document.createElement('div');
        this.textboxContainer.classList.add('dmx-slideshow-textbox-container');

        this.navContainer = document.createElement('div');
        this.navContainer.classList.add('dmx-slideshow-nav-container');

        this.pagingContainer = document.createElement('div');
        this.pagingContainer.classList.add('dmx-slideshow-paging-container');

        this.textboxElm = document.createElement('div');
        this.textboxElm.classList.add('dmx-slideshow-textbox');

        this.titleElm = document.createElement('div');
        this.titleElm.classList.add('dmx-slideshow-title');

        this.descriptionElm = document.createElement('div');
        this.descriptionElm.classList.add('dmx-slideshow-description');

        this.prevElm = document.createElement('div');
        this.prevElm.classList.add('dmx-slideshow-prev');

        this.nextElm = document.createElement('div');
        this.nextElm.classList.add('dmx-slideshow-next');

        this.pagesElm = document.createElement('ul');

        this.textboxElm.appendChild(this.titleElm);
        this.textboxElm.appendChild(this.descriptionElm);
        this.textboxContainer.appendChild(this.textboxElm);
        this.navContainer.appendChild(this.prevElm);
        this.navContainer.appendChild(this.nextElm);
        this.pagingContainer.appendChild(this.pagesElm);
        this.slidesContainer.appendChild(this.slider);
        this.$node.appendChild(this.slidesContainer);
        this.$node.appendChild(this.effectsContainer);
        this.$node.appendChild(this.textboxContainer);
        this.$node.appendChild(this.navContainer);
        this.$node.appendChild(this.pagingContainer);

        if (this.props['pause-on-hover']) {
            this.$node.addEventListener('mouseenter', this.pause.bind(this));
            this.$node.addEventListener('mouseleave', this.continue.bind(this));
        }

        this.$node.addEventListener('mousedown', this.touchStart.bind(this));
        this.$node.addEventListener('touchstart', this.touchStart.bind(this));

        var self = this;

        this.prevElm.addEventListener('mousedown', function(event) {
            event.stopPropagation();
        });

        this.prevElm.addEventListener('touchstart', function(event) {
            event.stopPropagation();
        });

        this.prevElm.addEventListener('click', function(event) {
            event.stopPropagation();
            self.prev();
        });

        this.nextElm.addEventListener('mousedown', function(event) {
            event.stopPropagation();
        });

        this.nextElm.addEventListener('touchstart', function(event) {
            event.stopPropagation();
        });

        this.nextElm.addEventListener('click', function(event) {
            event.stopPropagation();
            self.next();
        });

        //this.transitions = Object.keys(dmx.slideshow.transitions);
        this.slides = [];

        if (node.hasChildNodes()) {
            for (var i = 0; i < node.childNodes.length; i++) {
                var childNode = node.childNodes[i];
                var transitions = {};

                this.parseAttribute(childNode, 'transition').forEach(function(attr, i) {
                    if (dmx.slideshow.transitions[attr.argument]) {
                        transitions[attr.argument] = attr.modifiers;
                    }
                }, this);

                if (childNode.tagName == 'DMX-SLIDE') {
                    this.props.slides.push({
                        url: childNode.getAttribute('url'),
                        title: childNode.getAttribute('title'),
                        description: childNode.getAttribute('description'),
                        link: childNode.getAttribute('link'),
                        transitions: transitions
                    });
                }
            }
        }

        var custom = false;
        this.parseAttribute(node, 'transition').forEach(function(attr, i) {
            if (attr.argument == 'all') {
                Object.keys(dmx.slideshow.transitions).forEach(function(effect) {
                    this.props.transitions[effect] = {};
                }, this);
            }
            if (dmx.slideshow.transitions[attr.argument]) {
                if (!custom) {
                    delete this.props.transitions.default;
                    custom = true;
                }
                this.props.transitions[attr.argument] = attr.modifiers; //dmx.parse(attr.value || '{}', this);
            }
        }, this);

        this.update({});
    },

    update: function(props) {
        if (this.props['show-nav'] != props['show-nav']) {
            if (this.props['show-nav']) {
                this.navContainer.style.removeProperty('display');
            } else {
                this.navContainer.style.setProperty('display', 'none');
            }
        }

        if (this.props['show-paging'] != props['show-paging']) {
            if (this.props['show-paging']) {
                this.pagingContainer.style.removeProperty('display');
            } else {
                this.pagingContainer.style.setProperty('display', 'none');
            }
        }

        if (JSON.stringify(this.props.slides) != JSON.stringify(props.slides)) {
            this.stop();
            //this.slidesContainer.innerHTML = '';
            this.slider.innerHTML = '';
            this.effectsContainer.innerHTML = '';
            this.titleElm.innerHTML = '';
            this.descriptionElm.innerHTML = '';
            this.pagesElm.innerHTML = '';
            this.slides = this.props.slides.map(function(data) {
                var scope = new dmx.DataScope(data, this);
                var slide = {
                    loaded: false,
                    url: dmx.parse(this.props['slide-url'], scope),
                    width: 0,
                    height: 0,
                    title: dmx.parse(this.props['slide-title'], scope),
                    description: dmx.parse(this.props['slide-description'], scope),
                    link: dmx.parse(this.props['slide-link'], scope),
                    transitions: data.transitions || {}
                };
                var self = this;
                var image = new Image();
                image.onload = function() {
                    slide.loaded = true;
                    slide.width = this.width;
                    slide.height = this.height;
                    dmx.nextTick(function() {
                        if (!this.slides.filter(function(slide) { return !slide.loaded; }).length) {
                            this.setup();
                        }
                    }, self);
                };
                image.src = slide.url;
                return slide;
            }, this);
        }
    },

    setup: function() {
        var index = 0;

        this.slider.innerHTML = '';
        this.effectsContainer.innerHTML = '';
        this.titleElm.innerHTML = '';
        this.descriptionElm.innerHTML = '';
        this.pagesElm.innerHTML = '';

        this.$node.classList.remove('dmx-slideshow-loading');

        if (this.slides.length) {
            if (!this.$node.style.height) {
                this.$node.style.setProperty('padding-top', (this.slides[0].height * 100 / this.slides[0].width) + '%');
            }

            if (this.props['start-random']) {
                index = Math.floor(Math.random() * this.slides.length);
            }

            this.showText(index);
            this.showImage(index);

            this.pagesElm.innerHTML = '';
            this.slides.forEach(function(slide, i) {
                var self = this;
                var pageElm = document.createElement('li');
                //pageElm.innerText = i;
                pageElm.addEventListener('mousedown', function(event) {
                    event.stopPropagation();
                });

                pageElm.addEventListener('touchstart', function(event) {
                    event.stopPropagation();
                });
                pageElm.addEventListener('click', function(event) {
                    event.stopPropagation();
                    self.show(i)
                });
                if (index == i) {
                    pageElm.className = 'current';
                }
                this.pagesElm.appendChild(pageElm);

                var img = document.createElement('img');
                img.setAttribute('src', slide.url);
                img.style.setProperty('position', 'absolute');
                img.style.setProperty('width', '100%');
                img.style.setProperty('left', (i * 100) + '%');
                this.slider.appendChild(img);
            }, this);
        }

        this.set('index', index);

        if (!this.props['no-autostart']) {
            this.start();
        }
    },

    touchStart: function(event) {
        if (this.animating) return;
        if (event.targetTouches && event.targetTouches.length > 1) return;

        this.moved = false;
        this.moveStart = {
            pointer: (event.targetTouches ? event.targetTouches[0].pageX : event.pageX),
            slider: this.slider.offsetLeft,
            last: this.slider.offsetLeft
        };

        // remove any old listeners
        document.removeEventListener('mousemove', this.moveListener);
        window.removeEventListener('mouseup', this.endListener);
        this.$node.removeEventListener('touchmove', this.moveListener);
        this.$node.removeEventListener('touchend', this.endListener);

        if (event.type == 'touchstart') {
            this.$node.addEventListener('touchmove', this.moveListener);
            this.$node.addEventListener('touchend', this.endListener);
        } else {
            document.addEventListener('mousemove', this.moveListener);
            window.addEventListener('mouseup', this.endListener);
        }
    },

    touchMove: function(event) {
        if (event.targetTouches && event.targetTouches.length > 1) return;

        var delta = (event.targetTouches ? event.targetTouches[0].pageX : event.pageX) - this.moveStart.pointer;
        var x = this.moveStart.slider + delta;

        if (Math.abs(delta) > 10 && x < 0 && x > -(this.slides.length - 1) * this.$node.offsetWidth) {
            this.slider.style.setProperty('left', x + 'px');
            if (!this.moved) {
                this.hideText();
                this.moved = true;
            }
        }
    },

    touchEnd: function(event) {
        if (this.moved) {
            var round = this.moveStart.last < this.slider.offsetLeft ? 'ceil' : 'floor';
            var index = Math.abs(Math[round](this.slider.offsetLeft / this.$node.offsetWidth));
            this.show(index, true);
        } else {
            var slide = this.slides[this.data.index];
            if (slide.link) {
                document.location = slide.link;
            }
        }

        document.removeEventListener('mousemove', this.moveListener);
        window.removeEventListener('mouseup', this.endListener);
        this.$node.removeEventListener('touchmove', this.moveListener);
        this.$node.removeEventListener('touchend', this.endListener);
    },

    pause: function() {
        this.paused = true;
        clearTimeout(this.timer);
    },

    continue: function() {
        this.paused = false;
        if (this.data.running) {
            this.start();
        }
    },

    start: function() {
        clearTimeout(this.timer);
        this.timer = setTimeout(this.show.bind(this, this.data.index + 1), this.props.delay);
        this.set('running', true);
    },

    stop: function() {
        clearTimeout(this.timer);
        this.set('running', false);
    },

    prev: function(effect, options) {
        this.show(this.data.index - 1, false, effect, options);
    },

    next: function(effect, options) {
        this.show(this.data.index + 1, false, effect, options);
    },

    show: function(index, def, effect, options) {
        if (this.animating) return;

        if (index == null) {
            index = this.data.index + 1;
        }

        if (index >= this.slides.length) {
            index = 0;
        }

        if (index < 0) {
            index = this.slides.length - 1;
        }

        if (def) {
            this.animating = true;
            dmx.slideshow.transitions['default'](this).run(index);
        } else {
            this.transition(index, effect, options);
        }

        this.hideText();
        this.set('index', index);

        for (var i = 0; i < this.pagesElm.childNodes.length; i++) {
            this.pagesElm.childNodes[i].className = (index == i ? 'current' : '');
        }
    },

    showText: function(index) {
        this.titleElm.innerHTML = this.slides[index].title || '';
        this.descriptionElm.innerHTML = this.slides[index].description || '';
        if (this.slides[index].title || this.slides[index].description) {
            this.textboxElm.classList.add('dmx-slideshow-textbox-show');
        }
    },

    hideText: function() {
        this.textboxElm.classList.remove('dmx-slideshow-textbox-show');
    },

    showImage: function(index) {
        this.slider.style.setProperty('left', '-' + (index * 100) + '%');
    },

    transition: function(index, effect, options) {
        if (!effect) {
            var transitions = this.slides[index].transitions
            var effects = Object.keys(transitions);

            if (!effects.length) {
                transitions = this.props.transitions;
                effects = Object.keys(transitions);
            }

            effect = effects[Math.floor(Math.random() * effects.length)];
            options = transitions[effect];
        }

        this.animating = true;

        dmx.slideshow.transitions[effect](this, options).run(index);
    },

    transitionEnd: function() {
        this.animating = false;
        this.showImage(this.data.index);
        this.showText(this.data.index);
        this.slider.style.removeProperty('transition');
        if (this.data.running && !this.paused) {
            this.start();
        }
    },

    parseAttribute: function(node, attrName) {
        var attributes = [];
        var re = new RegExp('^' + attrName, 'i');

        if (node.nodeType == 1) {
            for (var i = 0; i < node.attributes.length; i++) {
                var attribute = node.attributes[i];

                if (attribute && attribute.specified && re.test(attribute.name)) {
                    var name = attribute.name;
                    var argument = null;
                    var modifiers = {};

                    name.split('.').forEach(function(part, i) {
                        if (i === 0) {
                            name = part;
                        } else {
                            var pos = part.indexOf(':');
                            if (pos > 0) {
                                modifiers[part.substr(0, pos)] = part.substr(pos + 1);
                            } else {
                                modifiers[part] = true;
                            }
                        }
                    });

                    var pos = name.indexOf(':');
                    if (pos > 0) {
                        argument = name.substr(pos + 1);
                        name = name.substr(0, pos);
                    }

                    attributes.push({
                        name: name,
                        fullName: attribute.name,
                        value: attribute.value,
                        argument: argument,
                        modifiers: modifiers
                    });
                }
            }
        }

        return attributes;
    }

});
